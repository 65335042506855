// import { Link } from "@StarberryUtils";
import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import {Col, Row, Container } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
import HelpCard from "./HelpCard/HelpCard"
// import BgShadowWhite from "../../../images/bg-shadow-white2.svg";
import  "../CanHelp/CanHelp.scss";

import { inViewOptions } from '../../utils/animations';
// import { motion } from "framer-motion";
// import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 40 
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const CanHelp=()=> {


  const data = useStaticQuery(graphql`
    query{
      strapiGlobalModules {
        How_Can_We_Help {
          Title
          Add_Links {
            Label
            Select_Link {
              id
            }
          }
        }
      }
    }
  `);

    return(
      <>
          <section
            className="help-sec components--Home--CanHelp--CanHelp"
          >
            <Container>
              <Row> 
                <Col md={12}>
                  <div variants={titleVariants}>
                    <ReactMarkdown source={data.strapiGlobalModules.How_Can_We_Help.Title ? data.strapiGlobalModules.How_Can_We_Help.Title : ''} allowDangerousHtml />
                    {/*<h2 className="sec-title text-center text-md-left">How can we  <span className="d-inline">help you?<img loading="lazy" className="shadow-img" src={BgShadowWhite} alt="underline"/></span></h2>*/}
                  </div>
                </Col>
              </Row>
              <HelpCard Links={data.strapiGlobalModules.How_Can_We_Help.Add_Links}/>
            </Container>
          </section>
      </>
    )
  }
export default CanHelp
