import React from 'react';
import { Link } from "@StarberryUtils";
import {Col, Row } from 'react-bootstrap';
import './HelpCard.scss';

import { motion } from "framer-motion";

import { GetURL } from "../../../common/site/functions";
import { GetMenuTileImageURL } from "../../../common/site/menu-tile-image-path";

const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      }
    }
}

const contentItem = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 }
}

const MotionRow = motion(Row)

const HelpCard = (props) => {
    return (
        <MotionRow
            className="help-list components--Home--CanHelp--HelpCard--HelpCard"
            variants={container}
        >
            {props.Links.map((link, index) => {
                let url = GetURL(link.Select_Link.id)
                let tile_image_url = GetMenuTileImageURL(link.Select_Link.id)
                return (
                    <Col md={6} lg={4} className="list-wrap" key={index}>
                        <motion.div
                            variants={contentItem}
                        >
                            <Link to={`/${url}`} className="list-item d-flex align-items-center">
                                <figure><img loading="lazy" src={tile_image_url} alt={`${link.Label} - Martyn Gerrard`} title="" /></figure>
                                <div className="d-flex justify-content-between align-items-center w-100"><strong>{link.Label}</strong> <span><i className="icon-longarrow-rgt"></i></span></div>
                            </Link>
                        </motion.div>
                    </Col>
                )
            })}
        </MotionRow>
    );
}

export default HelpCard;